import { render } from "react-dom";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./App.css";
import Root from "./pages/root/root";
import TimStories from "./pages/tim-stories/tim-stories";
import ThankYou from "./pages/thank-you/thank-you";
import VodafoneStories from "./pages/vodafone-stories/vodafone-stories";

function App() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Root />} />
                <Route path="thank-you" element={<ThankYou />} />
                <Route path="tim-stories" element={<TimStories />} />
                <Route path="vodafone-stories-4u" element={<VodafoneStories />} />
            </Routes>
        </BrowserRouter>
    );
}

export default App;
