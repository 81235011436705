import { useState, useEffect } from "react";
import "./form.css";

function Form(props) {
    const [operator, setOperator] = useState(null);
    const [disable, setDisable] = useState(true);

    function handleChange() {
        setDisable(!disable);
    }

    useEffect(() => {
        window.el_p = {
            aff_id: "Wnd1kEiIxmaQmBqtcnQlxJpQveQ2",
            id: props.formId,
            typ: "/thank-you",
        };

        const script = document.createElement("script");

        script.src = "https://form.elds.it/eleads-lib.js";
        script.async = true;

        document.body.appendChild(script);

        console.log("PROPS", props);

        return () => {
            document.body.removeChild(script);
        };
    }, []);

    return (
        <form>
            <div className="form-logo-container">
                <img src={props.logo} className="form-logo" />
            </div>

            <h1 className="title has-text-centered my-4 has-text-weight-bold" style={{ color: props.color }}>
                {props.header}
            </h1>
            <div className="eleads-form"></div>
        </form>
    );
}

export default Form;
