import "./tim-stories.css";
import Stories, { WithSeeMore } from "react-insta-stories";
import Form from "../../components/form/form.js";
import tim1 from "../../assets/img/tim-react-story-1.png";
import tim2 from "../../assets/img/tim-react-story-2.png";

import opLogo from "../../assets/img/logo-tim.jpg";
import seeMore from "../../assets/img/see-more.svg";

const infoPrivacy = "https://www.tim.it/web-privacy-policy";
const opValue = "1";
const opHeader = "Scopri le Offerte Riservate a Te";
const opColor = "#0433a3";
const offerType = "tim-mobile";

function TimStories(props) {
    return (
        <div className="story-container">
            <div className="story">
                <Stories
                    height={"100%"}
                    width={"100%"}
                    loop={false}
                    keyboardNavigation={true}
                    preventDefault={false}
                    stories={stories}
                />
            </div>
        </div>
    );
}

const stories = [
    {
        content: ({ action, story }) => {
            return (
                <WithSeeMore story={story} action={action}>
                    <div style={contentStylestoryback}>
                        <img style={image} src={tim1}></img>
                    </div>
                </WithSeeMore>
            );
        },
        seeMoreCollapsed: ({ toggleMore, action }) => (
            <div className="seemore-container" onClick={() => toggleMore(true)}>
                <img className="see-more" src={seeMore}></img>
            </div>
        ),
        seeMore: ({ close }) => (
            <div className="form-container-tim">
                <Form
                    logo={opLogo}
                    informativa={infoPrivacy}
                    selectvalue={opValue}
                    header={opHeader}
                    color={opColor}
                    type={offerType}
                />

                <div className="delete close-button is-pulled-right m-4" onClick={close} />
            </div>
        ),
    },
    {
        content: ({ action, story }) => {
            return (
                <WithSeeMore story={story} action={action}>
                    <div style={contentStylestoryback}>
                        <img style={image} src={tim2}></img>
                    </div>
                </WithSeeMore>
            );
        },
        seeMoreCollapsed: ({ toggleMore, action }) => (
            <div className="seemore-container" onClick={() => toggleMore(true)}>
                <img className="see-more" src={seeMore}></img>
            </div>
        ),
        seeMore: ({ close }) => (
            <div className="form-container-tim">
                <Form
                    logo={opLogo}
                    informativa={infoPrivacy}
                    selectvalue={opValue}
                    header={opHeader}
                    color={opColor}
                    type={offerType}
                />

                <div className="delete close-button is-pulled-right m-4" onClick={close} />
            </div>
        ),
    },

    {
        content: ({ story, action, isPaused }) => {
            action("pause");
            return (
                <div className="form-container-tim">
                    <Form
                        story={story}
                        isPaused={isPaused}
                        preventDefault={true}
                        logo={opLogo}
                        formId="zSypZS01f1HUK98gX4k3"
                        informativa={infoPrivacy}
                        selectvalue={opValue}
                        header={opHeader}
                        color={opColor}
                        type={offerType}
                    />
                </div>
            );
        },
    },
];
const image = {
    display: "block",
    maxWidth: "100%",
    borderRadius: 4,
    marginLeft: "auto",
    marginRight: "auto",
};

const contentStylestoryback = {
    background: "black",
    width: "100%",
    height: "100%",
    padding: 20,
    color: "white",
};

export default TimStories;
