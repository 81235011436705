import { Link } from "react-router-dom";

function Root() {
    return (
        <>
      <h1>HELLO</h1>
      <Link to="vodafone-stories">Vodafone</Link>
      <Link to="tim-stories">Tim</Link>
      </>
    );
  }
  
  export default Root;