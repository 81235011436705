import { Link } from "react-router-dom";
import "./thank-you.css";

function Root() {
    if (typeof window !== "undefined") {
        if (window.fbq != null) {
            window.fbq("track", "CompleteRegistration");
        }
    }

    return (
        <div className="typ">
            <h1 className="title">GRAZIE</h1>
            <p className="subtitle">Verrai contattato a breve!</p>
        </div>
    );
}

export default Root;
